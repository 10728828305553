import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Form } from "antd";
import "./stepVerificationTicket.scss";
import ComboField from "../../components/forms/fields/ComboField";
import SimpleInputField from "../../components/forms/fields/SimpleInputField";
import DateField from "../../components/forms/fields/DateField";
import NumberFormatField from "../../components/forms/fields/NumberFormatField";
import { ProductService } from "../../../common/services";
import CustomAntdForm from "../../components/forms/custom-antd-form";
import _ from "lodash";
import Btn from "../../components/Btn";
import PatternFormatNumberField from "../step4/PatternFormatNumberField";

const Step1 = ({ title, data, sCallback, eCallback }) => {
  const [form] = Form.useForm();
  const [selectedProduct, setSelectedProduct] = useState(data?.initForm?.productCode ?? null);
  const [selectedProductGrattage, setSelectedProductGrattage] = useState(null);
  const [datas, setData] = useState(null);
  const [alertErrors, setAlertErrors] = useState(null);
  const [state, setState] = useState({
    products: [],
    grattages : [],
    isProductloading: false,
    isTicketVerifying: false,
    isGrattageloading: false,
  });

  useEffect(() => {
    loadProducts();
    loadProductsGrattage();
  }, [form]);

  const loadProducts = () => {
    ProductService.getAll()
      .then((data) => {
        setState((state) => ({ ...state, products: data.data || [] }));
      })
      .catch((error) => console.log(error))
      .finally(setState((state) => ({ ...state, isProductsLoding: false })));
  };

  /**
   * Load type ticket grattage
   */

  const loadProductsGrattage = () => {
    ProductService.getAllTicketGrattage()
      .then((data) => {
        setState((state) => ({ ...state, grattages: data.data || [] }));
      })
      .catch((error) => console.log(error))
      .finally(setState((state) => ({ ...state, isGrattageloading: false })));
  };


  const onValidate = () => {
    setAlertErrors(null);
    form
      .validateFields()
      .then((values) => {
        console.log("values :::", values);

        // if (onSubmit) onSubmit(values, { status: true, gain: 4000 });

        const product = state?.products.find(
          (product) => product?.code === values?.productCode
        );

        const amount = _.replace(
          values?.montantTicket,
          new RegExp("[\\s-_]", "g"),
          ""
        );
        const dateTicket = _.replace(
          values?.dateTicket,
          new RegExp("[_]", "g"),
          ""
        );
        //console.log("dateTicket :::", new RegExp("[_]","g"));
        const verifyTicketRequest = {
          productId: product?.id,
          productCode: product?.code,
         // productGrattage: 
          ticketNumber:
            values?.productCode === "PR05" ? [values?.numTicket, values?.refTicket].join(",") :
              values?.productCode === 'PR07' ? [values?.typeGrattageId, values?.numTicket, values?.codeControle].join(",") :
                values?.numTicket,
          date: dateTicket,
          saleAmount: parseInt(amount) ?? null ,
        };
        console.log("verifyTicketRequest :::", verifyTicketRequest);

        setState((state) => ({ ...state, isTicketVerifying: true }));
        ProductService.verifyTicket(verifyTicketRequest)
          .then((response) => {
            console.log("response :::", response);
            if (response?.success && sCallback) {
              const verifyTicketResponse = {
                ticketNumber: verifyTicketRequest?.ticketNumber,
                gain: response?.data,
              };

              console.log("verifyTicket ::: ", verifyTicketResponse);
              sCallback(values, verifyTicketResponse);
            }
          })
          .catch((error) => {
            console.log(error);
            if (error?.success) {
              eCallback(values, error?.message?.replace("APP_ERROR:", ""));
            } else
              setAlertErrors(
                "Une erreur est survenue pendant le traitement de votre requête, veuillez réessayer"
              );
          })
          .finally(() => {
            setState((state) => ({ ...state, isTicketVerifying: false }));
          });
      })
      .catch((error) => console.log(error));
  };

  return (
    <React.Fragment>
      <h2 style={{ marginTop: 10, marginBottom: 5 }} className="step-vt-title">
        Vérification du ticket
      </h2>
      <Card className="step-verification-card ">
        <div className="ticket-container">
          <p className="card-titre roboto-medium no-display-on-mobile">
            {title}
          </p>
          <CustomAntdForm
            form={form}
            initialValues={data?.initForm}
            variant="filled"
            id="step1"
          >
            <ComboField
              name={"productCode"}
              label={"Nom du produit"}
              required={true}
              showSearch={false}
              options={(state?.products || []).map((product) => ({
                name: product?.libelle,
                value: product?.code,
              }))}
              onCodeSelected={setSelectedProduct}
            />

            {selectedProduct === "PR07" && (
              <ComboField
                  name={"typeGrattageId"}
                  label={"Type de ticket"}
                  required={true}
                  showSearch={false}
                  options={(state?.grattages || []).map((item) => ({
                    name: item?.name,
                    value: item?.id,
                  }))}
                  onCodeSelected={setSelectedProductGrattage}
                />
            )}

            <SimpleInputField
              name={"numTicket"}
              label={selectedProduct !== "PR07" ? "Numéro du ticket" : "Numéro serie"}
              placeholder={""}
              iProps={{ style: { width: "100%", textTransform: "capitalize" } }}
              size="medium"
              required={true}
              isCapitalise={true}
            />
            {selectedProduct === "PR07" && (<SimpleInputField
              name={"codeControle"}
              label={"Code controle"}
              placeholder={""}
              iProps={{ style: { width: "100%", textTransform: "capitalize" } }}
              size="medium"
              required={true}
              isCapitalise={true}
            />)}

            {selectedProduct === "PR05" && (
              <SimpleInputField
                name={"refTicket"}
                label={"Reference du ticket"}
                placeholder={""}
                iProps={{
                  style: { width: "100%", textTransform: "capitalize" },
                }}
                size="medium"
                required={true}
                isCapitalise={true}
              />
            )}

            {selectedProduct !== "PR07" && ( 
              <PatternFormatNumberField
                    label={"Date et heure"}
                    placeholder={"JJ-MM-AAAA"} 
                    format="##-##-####"
                    name={"dateTicket"}
                    required={true}
                  />    
              )}
            {selectedProduct !== 'PR07' && (
              <NumberFormatField
                label={"Montant (Mise)"}
                name={"montantTicket"}
                required={true}
              />
            )}
            {alertErrors && (
              <div className="animated" style={{ marginBottom: 10 }}>
                <Alert message={`${alertErrors}`} type="error" showIcon />
              </div>
            )}
            <div style={{display: 'flex', justifyContent: 'end'}}>
              <div><span style={{color: 'red'}}>*</span> Champs obligatoires</div>
            </div>
            <Btn
              title={
                state?.isTicketVerifying
                  ? "Vérification en cours ..."
                  : "Vérifier"
              }
              onClick={onValidate}
              loading={state?.isTicketVerifying}
            />
          </CustomAntdForm>
        </div>
      </Card>
      
    </React.Fragment>
  );
};

export default Step1;
