import { createContext } from "react";

export const API_BASE_URL = "https://payme-api.diginfactory.com"; //"http://192.168.1.5:8083";//


export const APP_NAME = "APP";

export const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: "accessToken",
  CURRENT_USER: "currentUser"
};

export const ACCESS_TOKEN = "accessToken";

export const CURRENT_USER = "currentUser";

export const AuthContext = createContext("AUTH_CONTEXT");

export const AppContext = createContext("APP_CONTEXT");
