import { Col, Form } from "antd";
import React, { useState } from "react";
import {PatternFormat} from "react-number-format";
import "../../components/forms/form-style.scss";

const PatternFormatNumberField=({label, placeholder, name, required=false, requiredMsg, format, disable, onChange })=> {
    
    const _onChange=(value)=> {
        console.log("_onChange : ", value?.target?.value)
        if(onChange && value) {
            onChange(value?.target?.value)
        }
    }

    return (
        <React.Fragment>
            <Form.Item
                style={{width: "100%"}}
                label={label}
                name={name}
                rules={[{ required: required, message: requiredMsg ?? "Champ obligatoire" }]}
                tooltip={{
                    title: required ? 'Champs obligatoire' : "",
                    icon: <span style={{color: 'red'}}>{required ? '*' : ''}</span>,
                  }}
            >
                <PatternFormat 
                    onChange={_onChange}
                    placeholder={placeholder}
                    format={format}
                    mask="_"
                    className='ant-input ant-input-lg css-dev-only-do-not-override-1qhpsh8 ant-input-outlined' 
                    disabled={disable}
                    />
            </Form.Item>
        </React.Fragment>
    )
}

export default PatternFormatNumberField;